import React, { useEffect, useRef, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const DataGridToolTip = ({ width, tooltipValue }) => {
  const tooltipRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const tooltipId = tooltipValue ? tooltipValue.toString() : '';

  useEffect(() => {
    if (tooltipRef.current) {
      const isOverflow =
        tooltipRef.current.scrollWidth > tooltipRef.current.clientWidth;
      setIsOverflowing(isOverflow);
    }
  }, [tooltipValue]);

  return (
    <>
      {tooltipId && (
        <div
          ref={tooltipRef}
          data-tooltip-id={isOverflowing ? tooltipId : null}
          data-tooltip-content={tooltipId}
          placement="bottom"
          style={{
            paddingTop: '5px',
            paddingBottom: '5px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {tooltipId}
        </div>
      )}
      {isOverflowing && (
        <ReactTooltip id={tooltipId} style={{ zIndex: 1000 }} />
      )}
    </>
  );
};

export default React.memo(DataGridToolTip);
